import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISettings } from '@core/domain/settings';
import { LOADING_STATUS, LoadingStatus } from '@core/types/loading-status';
import { merge, mergeWith, Observable, shareReplay, Subject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SettingsApiService {
    private SETTINGS_API: string = 'user/doc';

    private updateSettingsAction: Subject<ISettings> = new Subject<ISettings>();

    private settingsUpdates$: Observable<ISettings> = this.updateSettingsAction.pipe(
        switchMap((settings: ISettings) => this.http.put<ISettings>(this.SETTINGS_API, settings)),
        shareReplay(),
    );

    public initialSettings$: Observable<ISettings> = this.http.get<ISettings>(this.SETTINGS_API).pipe(shareReplay());

    public settings$: Observable<ISettings> = this.initialSettings$.pipe(mergeWith(this.settingsUpdates$));

    public loadingStatus$: Observable<LoadingStatus> = merge(
        this.updateSettingsAction.pipe(map(() => LOADING_STATUS.LOADING)),
        this.settings$.pipe(map(() => LOADING_STATUS.LOADED)),
    );

    constructor(private http: HttpClient) {
    }

    public onSettingsChanged(settings: ISettings): void {
        this.updateSettingsAction.next(settings);
    }
}
