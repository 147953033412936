import { Injectable, Provider, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SettingsApiService } from '@core/api/settings';
import { ISettings } from '@core/domain/settings';
import { LOADING_STATUS, LoadingStatus } from '@core/types/loading-status';
import { Observable } from 'rxjs';
import { ISettingsService, settingsServiceToken } from './settings.provider';

@Injectable()
export class SettingsService implements ISettingsService {
    public settings$: Observable<ISettings> = this.settingsApi.settings$;

    public settings: Signal<ISettings> = toSignal(this.settings$, {initialValue: {} as ISettings});

    public loadingStatus: Signal<LoadingStatus> = toSignal(this.settingsApi.loadingStatus$, {initialValue: LOADING_STATUS.LOADING});

    constructor(private settingsApi: SettingsApiService) {
    }

    public updateSettings(partialSettings: Partial<ISettings>): void {
        this.settingsApi.onSettingsChanged({
            ...this.settings(), ...partialSettings,
        });
    }
}

export const SETTINGS_SERVICE_PROVIDER: Provider = {
    provide: settingsServiceToken,
    useClass: SettingsService,
};
